import { msalInstance } from "."
import { tokenRequest } from "./authConfig";
import { InteractionRequiredAuthError } from "@azure/msal-browser";

// number of API call retries before giving up
const number_of_retries = 3;

// Handle Text-to-Speech file transformation
const submitTextToSpeech = async (fromFile, uKey, downFailKey, dKey) => {
  // Generate signed S3 URLs for upload/download
  const signedURLs = await getSignedURLs(uKey, downFailKey, dKey, "accessibleJob");
  
  // Upload source file to S3 Bucket
  let myHeaders = new Headers();

  let requestOptions = {
    method: 'PUT',
    headers: myHeaders,
    body: fromFile,
    redirect: 'follow'
  };
   
  const response = await fetch(String(signedURLs.putURL), requestOptions);

  if (response.ok) {
    return [String(signedURLs.getURL), String(signedURLs.failURL)];
  }
  else {
    console.log(response);
    throw new Error('ERROR TRIGGERED BY 4XX/5XX RESPONSE. SEE ABOVE INFORMATION.');
  }
}

// Handle Text Analysis file transformation
const submitTextAnalysis = async (fromFile, uKey, downFailKey, dKey) => {
  // Generate signed S3 URLs for upload/download
  const signedURLs = await getSignedURLs(uKey, downFailKey, dKey, "accessibleJob");
  
  // Upload source file to S3 Bucket
  let myHeaders = new Headers();

  let requestOptions = {
    method: 'PUT',
    headers: myHeaders,
    body: fromFile,
    redirect: 'follow'
  };
   
  const response = await fetch(String(signedURLs.putURL), requestOptions);

  if (response.ok) {
    return [String(signedURLs.getURL), String(signedURLs.failURL)];
  }
  else {
    console.log(response);
    throw new Error('ERROR TRIGGERED BY 4XX/5XX RESPONSE. SEE ABOVE INFORMATION.');
  }
}

// Handle Speech-to-Text file transformation
const submitSpeechToText = async (fromFile, uKey, downFailKey, dKey) => {
  // Generate signed S3 URLs for upload/download
  const signedURLs = await getSignedURLs(uKey, downFailKey, dKey, "accessibleJob");
  
  // Upload source file to S3 Bucket
  let myHeaders = new Headers();

  let requestOptions = {
    method: 'PUT',
    headers: myHeaders,
    body: fromFile,
    redirect: 'follow'
  };
   
  const response = await fetch(String(signedURLs.putURL), requestOptions);

  if (response.ok) {
    return [String(signedURLs.getURL), String(signedURLs.failURL)];
  }
  else {
    console.log(response);
    throw new Error('ERROR TRIGGERED BY 4XX/5XX RESPONSE. SEE ABOVE INFORMATION.');
  }
}

// Function to determine the user's gateway
const getUserGateway = async() => {
  // // Use with Dev (for future use once dev AWS account is set up)
  // const gatewayURL = "https://gateway.apim-c2c-dev.lilly.com/recommend-c2i-gateway";

  // Use with Qa (currently used with Dev environment as no dev AWS account exists)
  // const gatewayURL = "https://gateway.apim-c2c-qa.lilly.com/recommend-c2i-gateway";

  // Use with Prod
  const gatewayURL = "https://gateway.apim-c2c.lilly.com/recommend-c2i-gateway";

  try {
    const response = await fetch(gatewayURL, {method: 'GET'});

    if (response.ok) {
      const data = await response.json();

      if (data && typeof data.gateway == "string") {
        return data.gateway;
      }
      else {
        console.error("Invalid gateway format.");
        throw new Error("Invalid gateway format.");
      }
    }
    else { // If an HTTP error occurs
      console.error(`Failed to fetch users gateway: ${response.statusText}`);
      throw new Error(`Failed to fetch users gateway: ${response.statusText}`);
    }
  }// end try
  catch (error) { // If any unexpected error occurs (i.e. Network failure, timeout, etc.)
    console.error(`Error fetching gateway occurred: ${error.message}`);
    throw new Error(`Error fetching gateway occurred: ${error.message}`);
  }// end catch
}// end function

// Handle Raw Text Translation with Base Model
const submitText = async (postData) => {
  const gateway = await getUserGateway();

  // Set URL and backup URL_A  
  // Use with Dev (currently no dev environment...use Qa for dev)
  // const URL = `https://${gateway}/translate/api/textingest-01-dev`;
  // const backupGateway = gateway.includes("intranet") ? 
  //                       "gateway.apim-dev.lilly.com" : 
  //                       "gateway-intranet.apim-dev.lilly.com"; // assign backupGateway to the URL not being used by user
  // const URL_A = `https://${backupGateway}/translate/api/textingest-01-dev`;

  // Use with Qa
  // const URL = `https://${gateway}/translate/api/textingest-01-qa`;
  // const backupGateway = gateway.includes("intranet") ? 
  //                       "gateway.apim-qa.lilly.com" : 
  //                       "gateway-intranet.apim-qa.lilly.com"; // assign backupGateway to the URL not being used by user
  // const URL_A = `https://${backupGateway}/translate/api/textingest-01-qa`;

  // Use with Prod
  // const URL = `https://${gateway}/translate/api/textingest-01-prod`;
  // const backupGateway = gateway.includes("intranet") ? 
  //                       "gateway.apim.lilly.com" : 
  //                       "gateway-intranet.apim.lilly.com"; // assign backupGateway to the URL not being used by user
  // const URL_A = `https://${backupGateway}/translate/api/textingest-01-prod`;

  let URL = "";
  let URL_A = "";
  let backupGateway = "";

  // Used to apply the correct URL based on gateway response
  switch (gateway) {
    case "gateway.apim-dev.lilly.com" || "gateway-intranet.apim-dev.lilly.com":
      URL = `https://${gateway}/translate/api/textingest-01-prod`;
      backupGateway = gateway.includes("intranet") ? 
                            "gateway.apim-dev.lilly.com" : 
                            "gateway-intranet.apim-dev.lilly.com"; // assign backupGateway to the URL not being used by user
      URL_A = `https://${backupGateway}/translate/api/textingest-01-prod`;
      break;
    case "gateway.apim-qa.lilly.com" || "gateway-intranet.apim-qa.lilly.com":
      URL = `https://${gateway}/translate/api/textingest-01-prod`;
      backupGateway = gateway.includes("intranet") ? 
                            "gateway.apim-qa.lilly.com" : 
                            "gateway-intranet.apim-qa.lilly.com"; // assign backupGateway to the URL not being used by user
      URL_A = `https://${backupGateway}/translate/api/textingest-01-prod`;
      break;
    case "gateway.apim.lilly.com" || "gateway-intranet.apim.lilly.com":
      URL = `https://${gateway}/translate/api/textingest-01-prod`;
      backupGateway = gateway.includes("intranet") ? 
                            "gateway.apim.lilly.com" : 
                            "gateway-intranet.apim.lilly.com"; // assign backupGateway to the URL not being used by user
      URL_A = `https://${backupGateway}/translate/api/textingest-01-prod`;
      break;
    default:
      URL = `https://${gateway}/translate/api/textingest-01-prod`;
      backupGateway = gateway.includes("intranet") ? 
                            "gateway.apim-dev.lilly.com" : 
                            "gateway-intranet.apim-dev.lilly.com"; // assign backupGateway to the URL not being used by user
      console.error(`Gateway response is: ${gateway} and Backup Gateway response is: ${backupGateway}`);
      throw new Error(`Gateway response is: ${gateway} and Backup Gateway response is: ${backupGateway}`);
  }

  // console.error(`Gateway response is: ${gateway}
  //   \nBackup Gateway response is: ${backupGateway}
  //   \nURL configuration is: ${URL}
  //   \nURL_A configuration is: ${URL_A}`);

  let accessToken = "Bearer " + await getToken();
  
  // Don't see where this is getting used
  // const activeAccount = msalInstance.getActiveAccount();

  // Shouldn't need this anymore for the Raw Text Translations
  // // Make post request to API for Text Translation
  // const URL = String(process.env.REACT_APP_TEXT_API_URL)
  // const URL_A = String(process.env.REACT_APP_TEXT_API_BACKUP_URL_A)

  let myHeaders = new Headers();
  myHeaders.append("Authorization", accessToken);

  let raw = JSON.stringify(
    {
      "userId": postData.userId,
      "userCountry": postData.userCountry,
      "sourceLang": postData.sourceLang,
      "targetLang": postData.targetLang,
      "cci": postData.cci,
      "srcText": postData.srcText,
    }
  );

  let requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };
  
  // Call the primary URL from gateway check
  try {
    const response = await apiCallRetry(URL, requestOptions, number_of_retries);
    
    if (response && response.ok) {
      console.error(`Primary URL for API call is: ${response.status}`);
      return await response.json();
    }
    else {
      console.error(`Primary URL API call failed: [Status: ${response.status}, Status Text: ${response.statusText}]`);
      // throw new Error(`Primary URL API call failed: [Status: ${response.status}, Status Text: ${response.statusText}]`);
    }
  }
  catch (err) {
    console.error(`Error with primary URL API call: ${err.message}`);
    throw new Error(`Error with primary URL API call: ${err.message}`);
  }
  
  // Call the backup URL if the primary URL fails
  try {
    const backupResponse = await apiCallRetry(URL_A, requestOptions, number_of_retries);
    
    if (backupResponse && backupResponse.ok) {
      console.error(`Backup URL for API call is: ${backupResponse.status}`);
      return await backupResponse.json();
    }
    else {
      let err_msg = `Backup URL API call failed: [Status: ${backupResponse.status}, StatusText: ${backupResponse.statusText}]`;
      console.error(err_msg);
      throw new Error(err_msg);
    }// end else
  }// end try
  catch (err) {
    console.error(`Error with backup URL API call: ${err.message}`);
    throw new Error(`Error with backup URL API call: ${err.message}`);
  }// end catch

  // // Retry calling each of the available URLs until one of them succeeds
  // const response = await apiCallRetry(URL, requestOptions, number_of_retries);

  // try {
  //   if (response.ok) {
  //     return await response.json();
  //   }
  //   else {
  //     // Try with backup URL A
  //     const response = await apiCallRetry(URL_A, requestOptions, number_of_retries);
  
  //     if (response.ok) {
  //       return await response.json();
  //     }
  //     else {
  //       // If both text URL's have failed after retrying for each then we will report the error message
  //       console.error(response);
  //       let err_msg = "[".concat(String(response.status)).concat("-").concat(String(response.type)).concat("] ").concat(String(response.statusText));
  //       throw new Error(err_msg);
  //     }
  //   }
  // } catch (err1){
  //   // Try with backup URL A
  //   const response = await apiCallRetry(URL_A, requestOptions, number_of_retries);
  
  //   try {
  //     if (response.ok) {
  //       return await response.json();
  //     }
  //     else {
  //       // If both text URL's have failed after retrying for each then we will report the error message
  //       console.error(response);
  //       let err_msg = "[".concat(String(response.status)).concat("-").concat(String(response.type)).concat("] ").concat(String(response.statusText));
  //       throw new Error(err_msg);
  //     }
  //   } catch (err2) {
  //     // If both text URL's have failed after retrying for each then we will report the error message
  //     console.error(response);
  //     let err_msg = "[".concat(String(response.status)).concat("-").concat(String(response.type)).concat("] ").concat(String(response.statusText));
  //     throw new Error(err_msg);
  //   }
  // }
}

// Handle Raw Text Translation with Custom Models
// TODO: Consolidate this into a single function just like with the file translation, where "None" === base-model
const submitCustomText = async (postData) => {
  let accessToken = "Bearer " + await getToken();
  
  const activeAccount = msalInstance.getActiveAccount();

  // Make post request to API for Text Translation
  
  const URL = String(process.env.REACT_APP_TEXT_API_URL)
  const URL_A = String(process.env.REACT_APP_TEXT_API_BACKUP_URL_A)

  let myHeaders = new Headers();
  myHeaders.append("Authorization", accessToken);

  let raw = JSON.stringify({
    "userId": postData.userId,
    "userCountry": postData.userCountry,
    "sourceLang": postData.sourceLang,
    "targetLang": postData.targetLang,
    "cci": postData.cci,
    "model": postData.categoryID,
    "srcText": postData.srcText,
  });

  let requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: raw,
  redirect: 'follow'
  };
   
  // Retry calling each of the available URLs until one of them succeeds
  const response = await apiCallRetry(URL, requestOptions, number_of_retries);

  try {
    if (response.ok) {
      return await response.json();
    }
    else {
      // Try with backup URL A
      const response = await apiCallRetry(URL_A, requestOptions, number_of_retries);
  
      if (response.ok) {
        return await response.json();
      }
      else {
        // If all 4 text URL's have failed after retrying for each then we will report the error message
        console.error(response);
        let err_msg = "[".concat(String(response.status)).concat("-").concat(String(response.type)).concat("] ").concat(String(response.statusText));
        throw new Error(err_msg);
      }
    }
  } catch (err1){
    // Try with backup URL A
    const response = await apiCallRetry(URL_A, requestOptions, number_of_retries);
  
    try {
      if (response.ok) {
        return await response.json();
      }
      else {
        // If all 4 text URL's have failed after retrying for each then we will report the error message
        console.error(response);
        let err_msg = "[".concat(String(response.status)).concat("-").concat(String(response.type)).concat("] ").concat(String(response.statusText));
        throw new Error(err_msg);
      }
    } catch (err2) {
      // If both text URL's have failed after retrying for each then we will report the error message
      console.error(response);
      let err_msg = "[".concat(String(response.status)).concat("-").concat(String(response.type)).concat("] ").concat(String(response.statusText));
      throw new Error(err_msg);
    }
  }
}

// Handle File translation for the base model AND custom models
const submitFile = async (fromFile, uKey, downFailKey, dKey) => {
  // Generate signed S3 URLs for upload/download
  const signedURLs = await getSignedURLs(uKey, downFailKey, dKey, "translateJob");
  
  // Upload source file to S3 Bucket
  let myHeaders = new Headers();

  let requestOptions = {
    method: 'PUT',
    headers: myHeaders,
    body: fromFile,
    redirect: 'follow'
  };
   
  const response = await fetch(String(signedURLs.putURL), requestOptions);

  if (response.ok) {
    return [String(signedURLs.getURL), String(signedURLs.failURL)];
  }
  else {
    console.error(response);
    throw new Error('ERROR TRIGGERED BY 4XX/5XX RESPONSE. SEE ABOVE INFORMATION.');
  }
}

// Handle generation of signed S3 URL's for upload/download of source/target files
const getSignedURLs = async (ukey, downFailKey, dkey, jobType) => {
  const URL = String(process.env.REACT_APP_SIGNED_URLS_API_URL)
  let myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("x-api-key", String(process.env.REACT_APP_KEY));

  let raw = JSON.stringify({
    ukey: String(ukey),
    downFailKey: String(downFailKey),
    dkey: String(dkey),
    jobType: String(jobType)
  });

  let requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };
   
  const response = await fetch(URL, requestOptions);

    if (response.ok) {
      return await response.json();
    }
    else {
      console.error(response);
      throw new Error('An unexpected error occurred, please refresh the page and try again.');
    }
}

// Handle generation of API access tokens
const getToken = async () => {
  
  let token;
  
  await msalInstance.acquireTokenSilent(tokenRequest).then(tokenResponse => {
    // Token here is acquired through current session also called silent token call
    token = tokenResponse.accessToken;
  }).catch(error => {
    if (error instanceof InteractionRequiredAuthError) {
        // fallback to interaction when silent call fails
        return msalInstance.acquireTokenRedirect(tokenRequest).then(tokenResponse => {
          // Token here is acquired through the auth server
          token = tokenResponse.accessToken;
        })
    }
    else {
      // handle other errors
      console.error(error)
    }
  });
  // console.log(token);
  return token;
}

// Handle retrying the API multiple times
const apiCallRetry = async(url, requestOptions, retries) => {
  // Codes indicating function app temporary timeout
  const retryCodes = [500, 502, 503, 504, 522, 524];

  // Upgrade for better error handling and responses
  try {
    const res = await fetch(url, requestOptions);

    // If succeed then done
    if (res && res.ok) {
      return res;
    }

    // Recursively call apiCallRetry until either pass or use retry attempts
    if (retries > 0 && retryCodes.includes(res.status)) {
      console.warn(`Retrying API call. Status: ${res.status}`);
      return apiCallRetry(url, requestOptions, retries - 1);
    } 
    else {
      console.error(`API call failed with status: ${res.status}. All attempts failed.`);
      return res;
    }
  } 
  catch (err) {
    // Log and throw for handling through code
    console.error(`Error during API call: ${err.message}`);
    throw err;
  }

  // return fetch(url, requestOptions)
  // .then( res => {
  //     // if succeeded return immediately
  //     if (res.ok) return res;

  //     // Recursive call with retries decremented
  //     if (retries > 0 && retryCodes.includes(res.status)) {
  //         return apiCallRetry(url, requestOptions, retries-1);
  //     }
  //     else {
  //         console.error("*** FAILED TO CALL API REPEATEDLY ***");
  //         console.error(res);
  //         return res;
  //     } 
  // }).catch(console.error);
}

const API = {
  submitTextToSpeech,
  submitTextAnalysis,
  submitSpeechToText,
  submitText,
  submitCustomText,
  submitFile,
  getToken,
  apiCallRetry
};

export default API;