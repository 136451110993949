import { LogLevel } from "@azure/msal-browser";

export const msalConfig = {
  auth: {
      clientId: String(process.env.REACT_APP_CLIENT_ID),
      authority: `https://login.microsoftonline.com/${process.env.REACT_APP_TENANT_ID}`,
      redirectUri: String(process.env.REACT_APP_REDIRECT_URI),
      postLogoutRedirectUri: String(process.env.REACT_APP_REDIRECT_URI),
  },
  cache: {
      cacheLocation: "sessionStorage",
      storeAuthStateInCookie: false, 
  },
  system: {	
      loggerOptions: {	
          loggerCallback: (level, message, containsPii) => {	
              if (containsPii) {		
                  return;		
              }		
              switch (level) {		
                  case LogLevel.Error:		
                      console.error(message);		
                      return;		
                  case LogLevel.Info:		
                      console.info(message);		
                      return;		
                  case LogLevel.Verbose:		
                      console.debug(message);		
                      return;		
                  case LogLevel.Warning:		
                      console.warn(message);		
                      return;		
              }	
          }	
      }	
  }
};

export const loginRequest = {
  scopes: ["email", "offline_access", "openid", "profile"],
  forceRefresh: true
};


export const tokenRequest = {
  scopes: ['api://translate.lilly.com/.default'],
  grant_type: 'authorization_code',
  forceRefresh: false // Set this to "true" to skip a cached token and go to the server to get a new token
};
