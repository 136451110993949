// This file is the master control switches for enabling or disabling the UI buttons that allow users to interact with particular services.
// These attributes are controlled at a GLOBAL level using this file so that we can quickly enable/disable services.
// This gives simple control of outages to fix bugs or handle outside impacts to whether services can be made available to users.

const getServiceStatus = {
    disableTranslate: false,
    disableSpeech: false,
    disableTextAnalysis: false,
    disableVision: true
}

export default getServiceStatus;