import React, { useState, useEffect } from "react";
import { Switch, Route, withRouter, Redirect } from "react-router-dom";


import withRoot from "./HoC/withRoot";
import ErrorBoundary from "./components/ErrorBoundary";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import './styles/App.css';
import Homepage from "./containers/Homepage";
import Translate from "./containers/Translate";
import Speech from "./containers/Speech";
import TextAnalysis from "./containers/TextAnalysis";

import CustomFederatedSignIn from "./containers/CustomFederatedSignIn";
import Guide from "./containers/Guide";

// msal import
import { MsalProvider } from "@azure/msal-react";
import {AuthenticatedTemplate, UnauthenticatedTemplate} from "@azure/msal-react";

function App({pca}) {


  return (
    <MsalProvider instance={pca}>
      <AuthenticatedTemplate>
        <div className="appContainer">
        <ErrorBoundary>
          <NavBar className="appBarMain" />
          <div className="contentContainer">
            <Switch>
              <Route exact path="/" component={Homepage} />{" "}
              <Route exact path="/translate" component={Translate} />{" "}
              <Route exact path="/speech" component={Speech} />{" "}
              <Route exact path="/textanalysis" component={TextAnalysis} />{" "}
              <Route exact path="/guide" component={Guide} />{" "}
              <Route path="*" render={() => <Redirect to="/" />} />{" "}
            </Switch>{" "}
          </div>{" "}
          <Footer className="footerBarMain"/>
        </ErrorBoundary>
        </div>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <CustomFederatedSignIn />
      </UnauthenticatedTemplate>
    </MsalProvider>
 );
}

export default withRouter(withRoot(App));
