import API from "../API";


const getSentimentLangs = async () => {
  // URL for Supported translation languages
  // TODO: Fix automatic selection pulling from Azure
  try {
    const tokenURL = 'https://centralus.api.cognitive.microsoft.com/sts/v1.0/issuetoken';
    let tokenHeaders = new Headers();
    tokenHeaders.append("Ocp-Apim-Subscription-Key", String(process.env.REACT_APP_STT_RKEY));
    tokenHeaders.append("Content-type", "application/x-www-form-urlencoded");
    tokenHeaders.append("Content-Length", "0");

    let tokenRequestOptions = {
      method: 'POST',
      headers: tokenHeaders
    }

    const tokenResponse = await fetch(tokenURL, tokenRequestOptions)
    console.log(await tokenResponse.json())


    const URL = "https://centralus.tts.speech.microsoft.com/cognitiveservices/voices/list";
    let accessToken = "Bearer " + await fetch(tokenURL, tokenRequestOptions);
    let myHeaders = new Headers();
    
    // myHeaders.append("Ocp-Apim-Subscription-Key", String(process.env.REACT_APP_STT_RKEY));
    myHeaders.append("Authorization", accessToken);
    
    let requestOptions = {
      method : 'GET',
      headers: myHeaders,
      signal: AbortSignal.timeout(500)
    }

    const response = await fetch(URL,requestOptions)
    const resJson = await response.json()

    console.log(resJson)

    return resJson;
  }
  catch (e){
    console.error(e.message)
    console.log("Couldnt fetch languages here is a list of predefined ones")
    let sentimentData = [
      {
        "name": "",
        "code": ""
      },
      {
        "name": "English",
        "code": "en-us"
      },
      {
        "name": "French",
        "code": "fr"
      },
      {
        "name": "Italian",
        "code": "it"
      },
      {
        "name": "Spanish",
        "code": "es"
      },
      {
        "name": "German",
        "code": "de"
      },
      {
        "name": "Portuguese (Brazil)",
        "code": "pt-br"
      },
      {
        "name": "Portuguese (Portugal)",
        "code": "pt-pt"
      },
      {
        "name": "Afrikaans",
        "code": "af"
      },
      {
        "name": "Amharic",
        "code": "am"
      },
      {
        "name": "Arabic",
        "code": "ar"
      },
      {
        "name": "Assamese",
        "code": "as"
      },
      {
        "name": "Azerbaijani",
        "code": "az"
      },
      {
        "name": "Belarusian",
        "code": "be"
      },
      {
        "name": "Bulgarian",
        "code": "bg"
      },
      {
        "name": "Breton",
        "code": "br"
      },
      {
        "name": "Bosnian",
        "code": "bs"
      },
      {
        "name": "Catalan",
        "code": "ca"
      },
      {
        "name": "Czech",
        "code": "cs"
      },
      {
        "name": "Welsh",
        "code": "cy"
      },
      {
        "name": "Danish",
        "code": "da"
      },
      {
        "name": "Greek",
        "code": "el"
      },
      {
        "name": "Esperanto",
        "code": "eo"
      },
      {
        "name": "Estonian",
        "code": "et"
      },
      {
        "name": "Basque",
        "code": "eu"
      },
      {
        "name": "Persian",
        "code": "fa"
      },
      {
        "name": "Finnish",
        "code": "fi"
      },
      {
        "name": "Western Frisian",
        "code": "fy"
      },
      {
        "name": "Irish",
        "code": "ga"
      },
      {
        "name": "Scottish Gaelic",
        "code": "gd"
      },
      {
        "name": "Galician",
        "code": "gl"
      },
      {
        "name": "Gujarati",
        "code": "gu"
      },
      {
        "name": "Hausa",
        "code": "ha"
      },
      {
        "name": "Hebrew",
        "code": "he"
      },
      {
        "name": "Hindi",
        "code": "hi"
      },
      {
        "name": "Croatian",
        "code": "hr"
      },
      {
        "name": "Hungarian",
        "code": "hu"
      },
      {
        "name": "Armenian",
        "code": "hy"
      },
      {
        "name": "Indonesian",
        "code": "id"
      },
      {
        "name": "Japanese",
        "code": "ja"
      },
      {
        "name": "Javanese",
        "code": "jv"
      },
      {
        "name": "Georgian",
        "code": "ka"
      },
      {
        "name": "Kazakh",
        "code": "kk"
      },
      {
        "name": "Khmer",
        "code": "km"
      },
      {
        "name": "Kannada",
        "code": "kn"
      },
      {
        "name": "Korean",
        "code": "ko"
      },
      {
        "name": "Kurdish (Kurmanji)",
        "code": "ku"
      },
      {
        "name": "Kyrgyz",
        "code": "ky"
      },
      {
        "name": "Latin",
        "code": "la"
      },
      {
        "name": "Lao",
        "code": "lo"
      },
      {
        "name": "Lithuanian",
        "code": "lt"
      },
      {
        "name": "Latvian",
        "code": "lv"
      },
      {
        "name": "Malagasy",
        "code": "mg"
      },
      {
        "name": "Macedonian",
        "code": "mk"
      },
      {
        "name": "Malayalam",
        "code": "ml"
      },
      {
        "name": "Mongolian",
        "code": "mn"
      },
      {
        "name": "Marathi",
        "code": "mr"
      },
      {
        "name": "Malay",
        "code": "ms"
      },
      {
        "name": "Burmese",
        "code": "my"
      },
      {
        "name": "Nepali",
        "code": "ne"
      },
      {
        "name": "Dutch",
        "code": "nl"
      },
      {
        "name": "Norwegian (Bokmal)",
        "code": "nb"
      },
      {
        "name": "Odia",
        "code": "or"
      },
      {
        "name": "Punjabi",
        "code": "pa"
      },
      {
        "name": "Polish",
        "code": "pl"
      },
      {
        "name": "Pashto",
        "code": "ps"
      },
      {
        "name": "Romanian",
        "code": "ro"
      },
      {
        "name": "Russian",
        "code": "ru"
      },
      {
        "name": "Sanskrit",
        "code": "sa"
      },
      {
        "name": "Sindhi",
        "code": "sd"
      },
      {
        "name": "Sinhala",
        "code": "si"
      },
      {
        "name": "Slovak",
        "code": "sk"
      },
      {
        "name": "Slovenian",
        "code": "sl"
      },
      {
        "name": "Somali",
        "code": "so"
      },
      {
        "name": "Albanian",
        "code": "sq"
      },
      {
        "name": "Serbian",
        "code": "sr"
      },
      {
        "name": "Sundanese",
        "code": "su"
      },
      {
        "name": "Swedish",
        "code": "sv"
      },
      {
        "name": "Swahili",
        "code": "sw"
      },
      {
        "name": "Tamil",
        "code": "ta"
      },
      {
        "name": "Telugu",
        "code": "te"
      },
      {
        "name": "Thai",
        "code": "th"
      },
      {
        "name": "Filipino",
        "code": "tl"
      },
      {
        "name": "Turkish",
        "code": "tr"
      },
      {
        "name": "Uyghur",
        "code": "ug"
      },
      {
        "name": "Ukrainian",
        "code": "uk"
      },
      {
        "name": "Urdu",
        "code": "ur"
      },
      {
        "name": "Uzbek",
        "code": "uz"
      },
      {
        "name": "Vietnamese",
        "code": "vi"
      },
      {
        "name": "Xhosa",
        "code": "xh"
      },
      {
        "name": "Yiddish",
        "code": "yi"
      },
      {
        "name": "Chinese (Simplified)",
        "code": "zh-hans"
      },
      {
        "name": "Chinese (Traditional)",
        "code": "zh-hant"
      }
    ]

    return sentimentData
  }
}


export default getSentimentLangs;

// Format = [
//   {
//     locale: "et-EE",
//     language: "Estonian (Estonia)",
//     voices: [
//       {
//         name: "",
//         gender: ""
//       }
//       {
//         name: "AnuNeural",
//         gender: "(Female)"
//       },
//       {
//         name: "EduardNeural",
//         gender: "(Male)"
//       }
//     ],
//   },
// ]
