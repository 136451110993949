import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography
} from "@material-ui/core";

// *************************************************************************
// Overall Settings

const modalTitle = "Request API Access";

export default function ModalAPIKey () {

  // State Variable
  const [open, setOpen] = useState(false);

  // Setting up initial render 
  useEffect(() => {
    let visited = localStorage["MDUTranslateVisited"];
      
      if (visited) {
        setOpen(false)
      } 
      else {
        localStorage["MDUTranslateVisited"] = true;
        setOpen(true)
      }
  }, []);
    
    
  // *************************************************************************
  // Handle events

  const handleClickOpen = async () => {
    setOpen(true)
  };
  
  const handleClickClose = () => {
    setOpen(false)
  };

  // *************************************************************************
  // Build Components

  return (
    <div className="footerLinks">
      <Typography onClick={handleClickOpen}>
        {modalTitle}
      </Typography>
      <Dialog
        onClose={handleClickClose}
        aria-labelledby="modal-title"
        open={open}
      >
        <DialogTitle id="modal-title" className="modalTitle">
          {modalTitle}
        </DialogTitle>
        <DialogContent className="modalContent">
          <div align="left">
            <p>Translate can be interfaced with programatically from any other approved domain - details of our API service can be found in the guide section.</p>
            <p>Translate is currently approved for red data and there are no restrictions as to the types of documents that can be translated, but good business practices must be observed when using this translation service.</p>
            <p>Be knowledgeable of the type of content you are uploading to Translate, especially if it has the potential to contain personal information (PI).</p>
            <p>To request API access please email us at <strong><a href="mailto:LillyTranslate@lilly.com">LillyTranslate@lilly.com.</a></strong></p>
            <p>In your email, include your application name and briefly describe your use case.  A follow up review meeting will be required before access to our production API is provided. However, we can likely get you going in dev fairly quickly depending on the details of your request.</p>
            <p>As a reminder, we must comply with all legal requirements and company policies that apply to the collection, use, and retention of PI.</p>
            <p>Thank you for your continued partnership!</p>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClickClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
