import API from "../API";


const getSummarizeLangs = async () => {
  // URL for Supported translation languages
  // TODO: Fix automatic selection pulling from Azure
  try {
    const tokenURL = 'https://centralus.api.cognitive.microsoft.com/sts/v1.0/issuetoken';
    let tokenHeaders = new Headers();
    tokenHeaders.append("Ocp-Apim-Subscription-Key", String(process.env.REACT_APP_STT_RKEY));
    tokenHeaders.append("Content-type", "application/x-www-form-urlencoded");
    tokenHeaders.append("Content-Length", "0");

    let tokenRequestOptions = {
      method: 'POST',
      headers: tokenHeaders
    }

    const tokenResponse = await fetch(tokenURL, tokenRequestOptions)
    console.log(await tokenResponse.json())


    const URL = "https://centralus.tts.speech.microsoft.com/cognitiveservices/voices/list";
    let accessToken = "Bearer " + await fetch(tokenURL, tokenRequestOptions);
    let myHeaders = new Headers();
    
    // myHeaders.append("Ocp-Apim-Subscription-Key", String(process.env.REACT_APP_STT_RKEY));
    myHeaders.append("Authorization", accessToken);
    
    let requestOptions = {
      method : 'GET',
      headers: myHeaders,
      signal: AbortSignal.timeout(500)
    }

    const response = await fetch(URL,requestOptions)
    const resJson = await response.json()

    console.log(resJson)

    return resJson;
  }
  catch (e){
    console.error(e.message)
    console.log("Couldnt fetch languages here is a list of predefined ones")
    
    let summarizeData = [
      {
        "name": "",
        "code": ""
      },
      {
        "name": "English",
        "code": "en"
      },
      {
        "name": "French",
        "code": "fr"
      },
      {
        "name": "Italian",
        "code": "it"
      },
      {
        "name": "Spanish",
        "code": "es"
      },
      {
        "name": "German",
        "code": "de"
      },
      {
        "name": "Portuguese",
        "code": "pt"
      },
      {
        "name": "Hebrew",
        "code": "he"
      },
      {
        "name": "Japanese",
        "code": "ja"
      },
      {
        "name": "Korean",
        "code": "ko"
      },
      {
        "name": "Polish",
        "code": "pl"
      },
      {
        "name": "Chinese (Simplified)",
        "code": "zh-hans"
      }
    ] 

    return summarizeData
  }
}

const getSummarizeSentenceCount = async () => {

  const sentenceCount = [
    {
      display: "",
      code: "",
    },
    {
      display: "Auto",
      code: "Auto",
    },
    {
      display: "1",
      code: 1,
    },
    {
      display: "2",
      code: 2
    },
    {
      display: "3",
      code: 3
    },
    {
      display: "4",
      code: 4
    },
    {
      display: "5",
      code: 5
    },
    {
      display: "6",
      code: 6
    },
    {
      display: "7",
      code: 7
    },
    {
      display: "8",
      code: 8
    },
    {
      display: "9",
      code: 9
    },
    {
      display: "10",
      code: 10
    },
    {
      display: "11",
      code: 11
    },
    {
      display: "12",
      code: 12
    },
    {
      display: "13",
      code: 13
    },
    {
      display: "14",
      code: 14
    },
    {
      display: "15",
      code: 15
    },
    {
      display: "16",
      code: 16
    },
    {
      display: "17",
      code: 17
    },
    {
      display: "18",
      code: 18
    },
    {
      display: "19",
      code: 19
    },
    {
      display: "20",
      code: 20
    },


  ]
  return sentenceCount
}

const summarize = {
  getSummarizeLangs,
  getSummarizeSentenceCount
};

export default summarize;

