import React from "react";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true });

    console.error(error);
    console.log(info);
  }

  render() {
    if (this.state.hasError) {
      return <h1>A major issue has occured...<br/><br/>Please contact us at LillyTranslate@lilly.com. Thank you!</h1>;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
