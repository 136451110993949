const encodings = {
  '+': "%2B",
  '!': "%21",
  '"': "%22",
  '#': "%23",
  '$': "%24",
  '&': "%26",
  '\'': "%27",
  '(': "%28",
  ')': "%29",
  '*': "%2A",
  ',': "%2C",
  ':': "%3A",
  ';': "%3B",
  '=': "%3D",
  '?': "%3F",
  '@': "%40"
};

const decodings = {
  "%2B": '+',
  "%21": '!',
  "%22": '"',
  "%23": '#',
  "%24": '$',
  "%26": '&',
  "%27": '\'',
  "%28": '(',
  "%29": ')',
  "%2A": '*',
  "%2C": ',',
  "%3A": ':',
  "%3B": ';',
  "%3D": '=',
  "%3F": '?',
  "%40": '@'
};

const blanks = {
  '+': "_",
  '!': "_",
  '"': "_",
  '#': "_",
  '$': "_",
  '&': "_",
  '\'': "_",
  '(': "_",
  ')': "_",
  '*': "_",
  ',': "_",
  ':': "_",
  ';': "_",
  '=': "_",
  '?': "_",
  '@': "_",
  '%': "_",
  '^': "_",
  '|': "_",
  '-': "_",
  '`': "_",
  '[': "_",
  ']': "_",
  '{': "_",
  '}': "_",
  '~': "_"
};

export default {
  encode: function(str) {
    let encoded = encodeURIComponent(str)
      .replace(/(\+|!|"|#|\$|&|'|\(|\)|\*|,|:|;|=|\?|@)/img,
        function(match) { return encodings[match]; }
      );

    return encoded;
  },

  decode: function(str) {
    let decoded = decodeURIComponent(str)
      .replace(/(%2B|%21|%22|%23|%24|%26|%27|%28|%29|%2A|%2C|%3A|%3B|%3D|%3F|%40)/img,
        function(match) { return decodings[match]; }
      );

    return decoded;
  },

  removeSpecials: function(str) {
    let blanked = str
      .split(" ")
      .join("_")
      .replace(/(\+|%|!|"|#|\$|&|'|\(|\)|\*|,|:|;|=|\?|\^|\||-|`|\[|\]|\{|\}|~|@)/img,
        function(match) { return blanks[match]; }
      );

    return blanked;
  },

  isASCII: function(str) {
    // eslint-disable-next-line
    let ascii = /^[\x00-\xFF]*$/.test(str);
    return ascii;
  }
}
