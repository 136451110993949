import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import {
  MobileView,
  isMobile
} from "react-device-detect";
import { makeStyles } from '@material-ui/core/styles';
import {
  Drawer,
  CssBaseline,
  List,
  ListSubheader,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Collapse,
  Button
} from "@material-ui/core";
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import '../styles/translate-markdown.css';

import serviceStatuses from "../data/disable-services";

// *************************************************************************
// Markdown icons

import DirectionsTwoToneIcon from '@material-ui/icons/DirectionsTwoTone';
import FiberManualRecordTwoToneIcon from '@material-ui/icons/FiberManualRecordTwoTone';
import EmojiPeopleTwoToneIcon from '@material-ui/icons/EmojiPeopleTwoTone';
import SchoolTwoToneIcon from '@material-ui/icons/SchoolTwoTone';
import GroupAddTwoToneIcon from '@material-ui/icons/GroupAddTwoTone';
import SpeakerNotesTwoToneIcon from '@material-ui/icons/SpeakerNotesTwoTone';
import AlarmOnTwoToneIcon from '@material-ui/icons/AlarmOnTwoTone';
import AccountTreeTwoToneIcon from '@material-ui/icons/AccountTreeTwoTone';
import LanguageTwoToneIcon from '@material-ui/icons/LanguageTwoTone';
import GraphicEqTwoToneIcon from '@material-ui/icons/GraphicEqTwoTone';
import InfoTwoToneIcon from '@material-ui/icons/InfoTwoTone';

// *************************************************************************
// Markdown files

import GuideAbout from '../components/guide-about.md';
import GuideQuickStart from '../components/guide-quickstart.md';
import GuideLoggingIn from '../components/guide-loggingin.md';
import GuideTranslating from '../components/guide-translating.md';
import GuideSpeechServices from '../components/guide-speechservices.md';
import GuideTextServices from '../components/guide-textservices.md';
import GuideVisionServices from '../components/guide-visionservices.md';
import GuideFeatures from '../components/guide-features.md';
import GuideValidation from '../components/guide-validation.md';
import GuideTraining from '../components/guide-training.md';
import GuideTrained from '../components/guide-trained.md';
import GuideTeamLilly from '../components/guide-teamlilly.md';
import GuideSupport from '../components/guide-support.md';
import GuideApiGetStart from '../components/guide-apigetstart.md';
import GuideApiText from '../components/guide-apitext.md';
import GuideApiFile from '../components/guide-apifile.md';
import GuideApiAccessible from '../components/guide-apiaccessible.md';
import GuideApiLangCodes from '../components/guide-apilangcodes.md';
import GuideApiVoiceCodes from '../components/guide-apivoicecodes.md';

// *************************************************************************
// Overall Styling

const drawerWidth = 360;
const useStyles = makeStyles(theme => ({
  guideRoot: {
    display: 'flex',
    zIndex: 1
  },
  guideDrawer: {
    width: drawerWidth,
    flexShrink: 0,
    zIndex: 1,
  },
  guideDrawerPaper: {
    width: drawerWidth,
    zIndex: 2
  },
  guideList: {
    width: '100%',
    maxWidth: 420,
    backgroundColor: theme.palette.background.paper,
  },
  guideListItemNested: {
    paddingLeft: theme.spacing(4),
  },
  guideContent: {
    flexGrow: 1,
    padding: theme.spacing(3),
    align: "left",
  },
  guideToolbarSpacing: theme.mixins.toolbar,
}));

export default function Guide() {

  // *************************************************************************
  // Class config

  const classes = useStyles();

  // Markdown states for the navigation
  const [toggleTopics, setToggleTopics] = useState(isMobile ? false : true);
  const [mdContentSource, setContentSource] = useState("");
  const [mdGuideAbout, setGuideAbout] = useState("");
  const [mdGuideGettingStarted, setGuideGettingStarted] = useState(false);
  const [mdGuideCustomModels, setGuideCustomModels] = useState(false);
  const [mdGuideQuickStart, setGuideQuickStart] = useState("");
  const [mdGuideLoggingIn, setGuideLoggingIn] = useState("");
  const [mdGuideTranslating, setGuideTranslating] = useState("");
  const [mdGuideSpeechServices, setGuideSpeechServices] = useState("");
  const [mdGuideTextServices, setGuideTextServices] = useState("");
  const [mdGuideVisionServices, setGuideVisionServices] = useState("");
  const [mdGuideFeatures, setGuideFeatures] = useState("");
  const [mdGuideValidation, setGuideValidation] = useState("");
  const [mdGuideTraining, setGuideTraining] = useState("");
  const [mdGuideTrained, setGuideTrained] = useState("");
  const [mdGuideSupport, setGuideSupport] = useState("");
  const [mdGuideTeamLilly, setGuideTeamLilly] = useState("");
  const [mdGuideApi, setGuideApi] = useState(false);
  const [mdGuideApiGetStart, setGuideApiGetStart] = useState("");
  const [mdGuideApiText, setGuideApiText] = useState("");
  const [mdGuideApiFile, setGuideApiFile] = useState("");
  const [mdGuideApiAccessible, setGuideApiAccessible] = useState("");
  const [mdGuideApiLangCodes, setGuideApiLangCodes] = useState("");
  const [mdGuideApiVoiceCodes, setGuideApiVoiceCodes] = useState("");

  // Fetch the Markdown to keep *.md structure instead of converting to *.js
  useEffect(() => {
    fetch(GuideAbout).then((response) => response.text()).then((text) => {setGuideAbout(text);});
    fetch(GuideQuickStart).then((response) => response.text()).then((text) => {setGuideQuickStart(text);});
    fetch(GuideLoggingIn).then((response) => response.text()).then((text) => {setGuideLoggingIn(text);});
    fetch(GuideTranslating).then((response) => response.text()).then((text) => {setGuideTranslating(text);});
    fetch(GuideSpeechServices).then((response) => response.text()).then((text) => {setGuideSpeechServices(text);});
    fetch(GuideTextServices).then((response) => response.text()).then((text) => {setGuideTextServices(text);});
    fetch(GuideVisionServices).then((response) => response.text()).then((text) => {setGuideVisionServices(text);});
    fetch(GuideFeatures).then((response) => response.text()).then((text) => {setGuideFeatures(text);});
    fetch(GuideValidation).then((response) => response.text()).then((text) => {setGuideValidation(text);});
    fetch(GuideTraining).then((response) => response.text()).then((text) => {setGuideTraining(text);});
    fetch(GuideTrained).then((response) => response.text()).then((text) => {setGuideTrained(text);});
    fetch(GuideSupport).then((response) => response.text()).then((text) => {setGuideSupport(text);});
    fetch(GuideTeamLilly).then((response) => response.text()).then((text) => {setGuideTeamLilly(text);});
    fetch(GuideApiGetStart).then((response) => response.text()).then((text) => {setGuideApiGetStart(text);});
    fetch(GuideApiText).then((response) => response.text()).then((text) => {setGuideApiText(text);});
    fetch(GuideApiFile).then((response) => response.text()).then((text) => {setGuideApiFile(text);});
    fetch(GuideApiAccessible).then((response) => response.text()).then((text) => {setGuideApiAccessible(text);});
    fetch(GuideApiLangCodes).then((response) => response.text()).then((text) => {setGuideApiLangCodes(text);});
    fetch(GuideApiVoiceCodes).then((response) => response.text()).then((text) => {setGuideApiVoiceCodes(text);});
  }, []);

  // *************************************************************************
  // Handle events

  const handleClickToggleTopics = () => {
    setToggleTopics(!toggleTopics);
  }

  const handleContentUpdate = (markdownContent) => {
    setContentSource(markdownContent);
    window.scrollTo(0,0);

    if (isMobile) {
      setToggleTopics(!toggleTopics);
    }
  };

  const handleGuideAbout = () => {
    handleContentUpdate(mdGuideAbout);
  };

  const handleGuideGettingStarted = () => {
    setGuideGettingStarted(!mdGuideGettingStarted);
  };

  const handleGuideQuickStart = () => {
    handleContentUpdate(mdGuideQuickStart);
  };

  const handleGuideLoggingIn = () => {
    handleContentUpdate(mdGuideLoggingIn);
  };

  const handleGuideTranslating = () => {
    handleContentUpdate(mdGuideTranslating);
  };
  
  const handleGuideSpeechServices = () => {
    handleContentUpdate(mdGuideSpeechServices);
  };

  const handleGuideTextServices = () => {
    handleContentUpdate(mdGuideTextServices);
  };

  const handleGuideVisionServices = () => {
    handleContentUpdate(mdGuideVisionServices);
  };

  const handleGuideValidation = () => {
    handleContentUpdate(mdGuideValidation);
  };

  const handleGuideCustomModels = () => {
    setGuideCustomModels(!mdGuideCustomModels);
  };

  const handleGuideTraining = () => {
    handleContentUpdate(mdGuideTraining);
  };

  const handleGuideTrained = () => {
    handleContentUpdate(mdGuideTrained);
  };

  const handleGuideFeatures = () => {
    handleContentUpdate(mdGuideFeatures);
  };

  const handleGuideSupport = () => {
    handleContentUpdate(mdGuideSupport);
  };

  const handleGuideTeamLilly = () => {
    handleContentUpdate(mdGuideTeamLilly);
  };

  const handleGuideApi = () => {
    setGuideApi(!mdGuideApi);
  };

  const handleGuideApiGetStart = () => {
    handleContentUpdate(mdGuideApiGetStart);
  };

  const handleGuideApiText = () => {
    handleContentUpdate(mdGuideApiText);
  };

  const handleGuideApiFile = () => {
    handleContentUpdate(mdGuideApiFile);
  };

  const handleGuideApiAccessible = () => {
    handleContentUpdate(mdGuideApiAccessible);
  };

  const handleGuideApiLangCodes = () => {
    handleContentUpdate(mdGuideApiLangCodes);
  };

  const handleGuideApiVoiceCodes = () => {
    handleContentUpdate(mdGuideApiVoiceCodes);
  };

  // *************************************************************************
  // Build Components

  // TODO: Update the API Access section of the guide to split out the speech, text analysis, and vision services into separate sections
  // May also want to update the underlying Lambda to handle them separately, unclear at this time.

  return (
    <div className={classes.guideRoot}>
      <CssBaseline />
      {toggleTopics === true && (
        <Drawer
          className={classes.guideDrawer}
          variant="permanent"
          classes={{
            paper: classes.guideDrawerPaper
          }}
        >
          <div className={classes.guideToolbarSpacing} />
          <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            subheader={
              <ListSubheader component="div" id="nested-list-subheader">
                v5.2 Guide
              </ListSubheader>
            }
            className={classes.guideList}
          >
            <ListItem button onClick={handleGuideAbout}>
              <ListItemIcon>
                <DirectionsTwoToneIcon />
              </ListItemIcon>
              <ListItemText primary="About this Guide" />
            </ListItem>

            <ListItem button onClick={handleGuideGettingStarted}>
              <ListItemIcon>
                <AlarmOnTwoToneIcon />
              </ListItemIcon>
              <ListItemText primary="Getting Started" />
              {mdGuideGettingStarted ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={mdGuideGettingStarted} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem button className={classes.guideListItemNested} onClick={handleGuideQuickStart}>
                  <ListItemIcon>
                    <FiberManualRecordTwoToneIcon />
                  </ListItemIcon>
                  <ListItemText primary="Quick Start" />
                </ListItem>
                <ListItem button className={classes.guideListItemNested} onClick={handleGuideLoggingIn}>
                  <ListItemIcon>
                    <FiberManualRecordTwoToneIcon />
                  </ListItemIcon>
                  <ListItemText primary="Logging In" />
                </ListItem>
                <ListItem button className={classes.guideListItemNested} onClick={handleGuideTranslating} disabled={serviceStatuses.disableTranslate}>
                  <ListItemIcon>
                    <FiberManualRecordTwoToneIcon />
                  </ListItemIcon>
                  <ListItemText primary="Translation" />
                </ListItem>
                <ListItem button className={classes.guideListItemNested} onClick={handleGuideSpeechServices} disabled={serviceStatuses.disableSpeech}>
                  <ListItemIcon>
                    <FiberManualRecordTwoToneIcon />
                  </ListItemIcon>
                  <ListItemText primary="Speech Services" />
                </ListItem>
                <ListItem button className={classes.guideListItemNested} onClick={handleGuideTextServices} disabled={serviceStatuses.disableTextAnalysis}>
                  <ListItemIcon>
                    <FiberManualRecordTwoToneIcon />
                  </ListItemIcon>
                  <ListItemText primary="Text Analysis Services" />
                </ListItem>
                <ListItem button className={classes.guideListItemNested} onClick={handleGuideVisionServices} disabled={serviceStatuses.disableVision}>
                  <ListItemIcon>
                    <FiberManualRecordTwoToneIcon />
                  </ListItemIcon>
                  <ListItemText primary="Vision Services" />
                </ListItem>
                <ListItem button className={classes.guideListItemNested} onClick={handleGuideValidation}>
                  <ListItemIcon>
                    <FiberManualRecordTwoToneIcon />
                  </ListItemIcon>
                  <ListItemText primary="System Validity" />
                </ListItem>
              </List>
            </Collapse>

            <ListItem button onClick={handleGuideCustomModels}>
              <ListItemIcon>
                <SchoolTwoToneIcon />
              </ListItemIcon>
              <ListItemText primary="Custom Translate Models" />
              {mdGuideCustomModels ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={mdGuideCustomModels} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem button className={classes.guideListItemNested} onClick={handleGuideTraining}>
                  <ListItemIcon>
                    <FiberManualRecordTwoToneIcon />
                  </ListItemIcon>
                  <ListItemText primary="Training a Model" />
                </ListItem>
                <ListItem button className={classes.guideListItemNested} onClick={handleGuideTrained}>
                  <ListItemIcon>
                    <FiberManualRecordTwoToneIcon />
                  </ListItemIcon>
                  <ListItemText primary="Available Models" />
                </ListItem>
              </List>
            </Collapse>

            <ListItem button onClick={handleGuideTeamLilly}>
              <ListItemIcon>
                <GroupAddTwoToneIcon />
              </ListItemIcon>
              <ListItemText primary="Team Lilly!" />
            </ListItem>
            
            <ListItem button onClick={handleGuideFeatures}>
              <ListItemIcon>
                <SpeakerNotesTwoToneIcon />
              </ListItemIcon>
              <ListItemText primary="Features" />
            </ListItem>

            <ListItem button onClick={handleGuideSupport}>
              <ListItemIcon>
                <EmojiPeopleTwoToneIcon />
              </ListItemIcon>
              <ListItemText primary="Support" />
            </ListItem>

            <Divider />

            <ListItem button onClick={handleGuideApi}>
              <ListItemIcon>
                <AccountTreeTwoToneIcon />
              </ListItemIcon>
              <ListItemText primary="API Integration" />
              {mdGuideApi ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={mdGuideApi} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem button className={classes.guideListItemNested} onClick={handleGuideApiGetStart}>
                  <ListItemIcon>
                    <InfoTwoToneIcon />
                  </ListItemIcon>
                  <ListItemText primary="API Starting Info" />
                </ListItem>
                <ListItem button className={classes.guideListItemNested} onClick={handleGuideApiText}  disabled={serviceStatuses.disableTranslate}>
                  <ListItemIcon>
                    <FiberManualRecordTwoToneIcon />
                  </ListItemIcon>
                  <ListItemText primary="Text Translation API" />
                </ListItem>
                <ListItem button className={classes.guideListItemNested} onClick={handleGuideApiFile}  disabled={serviceStatuses.disableTranslate}>
                  <ListItemIcon>
                    <FiberManualRecordTwoToneIcon />
                  </ListItemIcon>
                  <ListItemText primary="File Translation API" />
                </ListItem>
                <ListItem button className={classes.guideListItemNested} onClick={handleGuideApiAccessible} disabled={serviceStatuses.disableSpeech}>
                  <ListItemIcon>
                    <FiberManualRecordTwoToneIcon />
                  </ListItemIcon>
                  <ListItemText primary="Accessible Services API" />
                </ListItem>
                <ListItem button className={classes.guideListItemNested} onClick={handleGuideApiLangCodes} disabled={serviceStatuses.disableTranslate}>
                  <ListItemIcon>
                    <LanguageTwoToneIcon />
                  </ListItemIcon>
                  <ListItemText primary="Language Codes" />
                </ListItem>
                <ListItem button className={classes.guideListItemNested} onClick={handleGuideApiVoiceCodes} disabled={serviceStatuses.disableSpeech}>
                  <ListItemIcon>
                    <GraphicEqTwoToneIcon />
                  </ListItemIcon>
                  <ListItemText primary="Voice Codes" />
                </ListItem>
              </List>
            </Collapse>
          </List>
          <div className={classes.guideToolbarSpacing} />
        </Drawer>
      )}
      <main className={classes.guideContent}>
        <div className={classes.guideToolbarSpacing} />
        <div className="markdown-body" align="left">

          <MobileView>
          <Button
            variant="contained"
            color="primary"
            onClick={handleClickToggleTopics}
          >
            Show Guide Topics
          </Button>
          </MobileView>

          {mdContentSource !== "" && (
            <ReactMarkdown remarkPlugins={[remarkGfm]}>{mdContentSource}</ReactMarkdown>
          )}
          {mdContentSource === "" && (
            <ReactMarkdown remarkPlugins={[remarkGfm]}>{mdGuideAbout}</ReactMarkdown>
          )}

          <MobileView>
          <Button
            variant="contained"
            color="primary"
            onClick={handleClickToggleTopics}
          >
            Show Guide Topics
          </Button>
          </MobileView>

        </div>
        <div className={classes.guideToolbarSpacing} />
      </main>
    </div>
  );
}

// TODO: Add the links to the serviceNow requests that need to be made in order to get API access in a new tab
// - Also see if there is a way to auto populate some of the ticket info when we link them out (nice to have)