import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography
} from "@material-ui/core";

// *************************************************************************
// Overall Settings

const modalTitle = "Tips & FAQs";

export default function ModalFAQ() {

  // State Variable
  const [open, setOpen] = useState(false);

  // Setting up initial render 
  useEffect(() => {
    let visited = localStorage["MDUTranslateVisited"];
      
      if (visited) {
        setOpen(false)
      } 
      else {
        localStorage["MDUTranslateVisited"] = true;
        setOpen(true)
      }
  }, []);
    
    
  // *************************************************************************
  // Handle events

  const handleClickOpen = async () => {
    setOpen(true)
  };
  
  const handleClickClose = () => {
    setOpen(false)
  };

  // *************************************************************************
  // Build Components

  return (
    <div className="footerLinks">
      <Typography onClick={handleClickOpen}>
        {modalTitle}
      </Typography>
      <Dialog
        onClose={handleClickClose}
        aria-labelledby="modal-title"
        open={open}
      >
        <DialogTitle id="modal-title" className="modalTitle">
          {modalTitle}
        </DialogTitle>
        <DialogContent className="modalContent">
          <div align="left">
            <p><strong>Tips and Answers to FAQs</strong></p>
      <p><strong>1</strong> Currently, PDF support is primarily limited to non-scanned PDF's. Scanned PDF's may work in limited capacity with full support planned for a future update.</p>
      <p><strong>2</strong> Documents with hypens and underscores '- _' in their titles will sometimes break the upload function.  Remove them and try again.</p>
      <p><strong>3</strong> Documents with special characters and accents on letters - eg: '^´' in their titles will sometimes break the upload function.  Remove them and try again.</p>
      <p><strong>4</strong> Extremely large documents will cause the upload function to timeout - try spliiting your document into multiple files.</p>
      <p><strong>5</strong> Advanced Translation using custom, domain specific models are not currently available. The 5.0 release with support for them is slated for November 2023.</p>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClickClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
